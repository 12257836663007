import React, { useEffect, useState } from "react";
import { MapContainer, TileLayer, Marker, Popup, LayersControl, LayerGroup, FeatureGroup, Polyline, useMapEvents } from 'react-leaflet'
import ReactTooltip from "react-tooltip";
import { selectors as CatalogSelectors } from '../store/slices/catalog'
import { useDispatch, useSelector } from "react-redux";
import { push } from 'connected-react-router';
import Flag from 'react-world-flags'
import "./os_labsmap.css"
import {
    Row, Col,
    Card, CardText, CardBody,
    CardHeader, CardFooter, CardTitle, CardSubtitle,
    Spinner
} from 'reactstrap';
import MarkerClusterGroup from 'react-leaflet-cluster'
import moment from 'moment';
import schools from '../schools/schools_2022_23_v3.json'
import { actions as UsersActions, selectors as UsersSelectors } from '../store/slices/users'
import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';
import L from 'leaflet';
import { useTranslation } from 'react-i18next';
import { selectors as AppointmentsSelector, actions as AppointmentActions } from '../store/slices/appointments'


//https://stackoverflow.com/questions/65082167/obtain-a-segment-of-polyline-which-was-clicked

let DefaultIcon = L.icon({
    iconUrl: icon,
    shadowUrl: iconShadow
});

const isValidLatLng = (lat, lng, origin) => {
    if (
      lat && lng &&
      typeof lat === "number" &&
      typeof lng === "number" &&
      lat >= -90 &&
      lat <= 90 &&
      lng >= -180 &&
      lng <= 180
    ) {
      return true;
    }
    console.log(`OSMD: Invalid lat: ${lat} lng: ${lng} ORIGIN: ${origin}`);
    return false;
  };
  
const schoolMarkerIcon = new L.Icon({
    iconUrl: require("./schoolMarker.svg").default,
    iconSize: new L.Point(40, 47)
});

const labMarkerIcon = new L.Icon({
    iconUrl: require("./labMarker.svg").default,
    iconSize: new L.Point(40, 47)
});

L.Marker.prototype.options.icon = DefaultIcon;


const ExperimentContextPreview = (props) => {
    const experiment = props.experiment;
    const { t } = useTranslation('frontend', { useSuspense: false });
    const dispatch = useDispatch();
    const [appointments, setAppointments] = useState([])


    const [amountOfAppointments, setAmountOfAppointments] = useState([0, 0])

    useEffect(() => {

        setAppointments(props.appointments?.filter((app) => {
            return app["title"] == experiment["id"]
        }));

    }, []);

    useEffect(() => {
        if (appointments == null)
            setAmountOfAppointments([0, 0])
        else {
            let pastAppointments = 0
            let nextAppointments = 0
            for (let i = 0; i < appointments.length; i++) {
                moment(appointments[i]["startDate"]).isBefore(moment.now()) ?
                    pastAppointments += 1 : nextAppointments += 1;

            }
            setAmountOfAppointments([pastAppointments, nextAppointments]);

        }

    }, [appointments])


    const renderTimelineFlags = (content) => {

        const timelines = content.timeline_pubbliche;
        if (timelines == null || timelines.length < 1) return null;
        const languages = new Set()
        for (let i = 0; i < timelines; i++) {
            if (timelines[i]["languages"] != null)
                languages.add(timelines[i]["languages"])
            else languages.add("IT");
        }
        if (languages.size < 1) { languages.add("IT"); }
        //console.log("LANGUAGES:::", languages)

        return (
            <span style={{ display: "flex", justifyContent: "flex-start" }}>
                <b>{t("Timeline disponibili in")}</b>
                {Array.from(languages).map((language, index) => {
                    return <Flag key={index} code={language} height="14" style={{ "marginTop": "2px", "marginLeft": "5px", "marginRight": "5px" }} />
                })}
            </span>
        )
    }

    const renderLiveSessionsFlags = (content) => {
        //Codici dei paesi: https://en.wikipedia.org/wiki/ISO_3166-1_alpha-3
        if (!content.live) return null;
        const countries = content.languages || ["IT"];
        return (
            <span style={{ display: "flex", justifyContent: "flex-start" }}>
                <b>{t("Sessioni sincrone disponibili in")}</b>
                {countries.map((language, index) => {
                    return (
                        <Flag key={index} code={language} height="14" style={{ "marginTop": "2px", "marginLeft": "5px", "marginRight": "5px" }} />
                    )
                })}
            </span>
        )
    }

    return (<Card key={props.key}>
        <CardHeader >
            <CardTitle>
                <b>{experiment["titolo"]}</b>
            </CardTitle>

        </CardHeader>
        <CardBody>

            <CardText><b>{t("Laboratorio")}:</b><br />{experiment["laboratorio"]}</CardText>
            <CardText><b>{t("Sessioni sincrone passate")}{`: `}</b>{amountOfAppointments[0]}</CardText>
            <CardText><b>{t("Sessioni sincrone in programma")}{`: `}</b>{amountOfAppointments[1]}</CardText>
            <CardText>{renderTimelineFlags(experiment)}</CardText>
            <CardText>{renderLiveSessionsFlags(experiment)}</CardText>
        </CardBody>
        <CardFooter>
            <CardText style={{ display: "flex", justifyContent: "center" }}>
                <b><a href="#" onClick={() => { dispatch(push(`/experiment/${experiment.id}`)); }} >
                    {t("Vedi scheda completa e disponibilità")}
                </a></b>
            </CardText>
        </CardFooter>
    </Card>
    )

}

const LabContextPreview = (props) => {
    const dispatch = useDispatch();
    const { t } = useTranslation('frontend', { useSuspense: false });
    const lab = props.lab
    ////console.log("LabContextPreview exp:", props.experiments)
    const experiments = (props.experiments && Object.values(props.experiments).filter((experiment) => {
        return experiment["labs_id"] && experiment["labs_id"].includes(parseInt(lab["lab_id"]))
    }) || []);
    return (
        <Card>
            <CardHeader>
                <CardTitle>
                    <b>{lab["titolo"]}</b>
                </CardTitle>
            </CardHeader>
            <CardBody>
                <CardTitle>
                    <b>{t("Esperimenti in catalogo")}</b>
                </CardTitle>
                {experiments.map(experiment => {
                    return (
                        <div data-id="LabContextPreview" data-tip={t("Vedi scheda completa e disponibilità")} key={experiment.id}>
                            <CardTitle>
                                <a href="#" onClick={() => { dispatch(push(`/experiment/${experiment.id}`)); }} >
                                    {experiment["titolo"]}
                                </a>
                            </CardTitle>
                            <ReactTooltip id="LabContextPreview" />
                        </div>)
                })}
            </CardBody>

        </Card>
    )
}

const SchoolContextPreview = (props) => {
    const { t } = useTranslation('frontend', { useSuspense: false });
    const school = props.schoolContext["school"]
    const amountOfTeachers = props.schoolContext["count"]
    return (
        <Card>
            <CardHeader>
                <CardTitle>
                    <b>{`${school["nome_istituto_riferimento"]}`}</b>
                </CardTitle>
                <CardSubtitle>
                    {`${school["indirizzo"]},${school["comune"]}`}
                </CardSubtitle>
            </CardHeader>
            <CardBody>
                <CardText>
                    <b>{`${t("Codice Meccanografico")}: `}</b>{`${school["codice_meccanografico"]}`}
                </CardText>
                <CardText>
                    <b>{`${t("Tipo di scuola")}: `}</b>{`${school["tipo"]}`}
                </CardText>
            </CardBody>
            <CardFooter>
                <CardTitle>
                    <b>{`${t("Docenti coinvolti")}: ${amountOfTeachers}`}</b>
                </CardTitle>
            </CardFooter>
        </Card>
    )
}


const ClusteringSelector = (props) => {

    const { t } = useTranslation('frontend', { useSuspense: false });
    const handleClusterChange = (e) => {
        props.onClusterChange(e.target.checked);
    };

    return (
        <div style={{
            position: 'absolute',
            bottom: '10px',
            left: '10px',
            padding: '10px',
            background: 'white',
            border: '1px solid #ccc',
            borderRadius: '4px',
            zIndex: 1000
        }}>
            <label>
                <input type="checkbox" checked={props.checked} onChange={handleClusterChange} />
                <span style={{ "margin": "5px" }}>{t("Raggruppa marker")} </span>
            </label>
        </div>
    );
};



export const RialeGeoNet = (props) => {
    const experiments = useSelector(CatalogSelectors.getExperiments);
    const [appointmentToLabPaths, setAppointmentToLabPaths] = useState([])
    const labs = useSelector(CatalogSelectors.getLabs);
    const registeredUsers = useSelector(UsersSelectors.getRegisteredUsers);
    const appointments = useSelector(AppointmentsSelector.getAppointments)
    const { t } = useTranslation('frontend', { useSuspense: false });
    const dispatch = useDispatch();
    const [schoolMarkers, setSchoolMarkers] = useState([])
    const [clusterDisabledAtZoom, setClusterDisabledAtZoom] = useState(1);
    const [sessionSchoolMarkers, setSessionSchoolMarkers] = useState([])
    const [sessionLabMarkers, setSessionLabMarkers] = useState({})
    const [registeredUsersDict, setRegisteredUsersDict] = useState({})


    function LocationMarker() {
        const [position, setPosition] = useState(null)
        const map = useMapEvents({
            click(e) {
                console.log("CLICK ON MAP:", e.latlng, map.getZoom())
                map.locate()
            },
            locationfound(e) {
                console.log("COORDINATE:", e.latlng, map.getZoom())
                setPosition(e.latlng)
                map.flyTo(e.latlng, map.getZoom())
            },
        })

        return position === null ? null : (
            <Marker position={position}>
                <Popup>You are here</Popup>
            </Marker>
        )
    }

    const getSchoolCode = (schoolInfo) => {
        const isValid = new RegExp('^[(][A-Z0-9]{10}[)]')
        if (!isValid.test(schoolInfo)) return null;
        return schoolInfo.slice(1, 11)
    }

    const getAttendeeProfileSchoolInfo = (userId) => {
        if (!userId || !registeredUsers) return null;
        return registeredUsersDict[userId] && registeredUsersDict[userId]["school"]
    }

    const buildPaths = async () => {
        //console.log(`GEOPATH: Build path su ${appointments.length} appuntamenti e ${registeredUsers.length} utenti`)
        const paths = []
        const sessionSchools = {}
        const sessionLabs = {}

        for (let i = 0; i < appointments.length; i++) {
            const attendees = appointments[i]["attendees"];
            if (attendees == null || attendees.length < 1) continue;

            for (let j = 0; j < attendees.length; j++) {
                const schoolInfo = attendees[j]["school"]
                    // se si inserisce la condizione seguente si inseriscono nel conteggio
                    // delle potenziali scuole estranee alle sessioni svolte nel caso
                    // il docente abbia successivamente cambiato scuola e aggiornato il 
                    // proprio profilo 
                    //|| getAttendeeProfileSchoolInfo(attendees[j]["user"])
                    ;
                if (schoolInfo == null) continue;
                //console.log(`GEOPATH: scuola candidata: ${schoolInfo}`, appointments[i])
                const codiceMec = getSchoolCode(schoolInfo);
                if (codiceMec == null) continue;

                if (sessionSchools[codiceMec] == null)
                    sessionSchools[codiceMec] = { "school": schools[codiceMec], "count": 1 }
                else
                    sessionSchools[codiceMec]["count"] += 1

                //console.log(`GEOPATH: trovato ${codiceMec} valido`)
                const schoolCoordinates = [schools[codiceMec]["latitudine"],
                schools[codiceMec]["longitudine"]]

                const experiment = experiments[appointments[i]["title"]]
                // Un esperimento fa capo a una lista di potenziali laboratori
                // li considero tutti

                // bug patch: con le sessioni pratiche alcuni appuntamenti sono mappati
                // su lab inesistenti... li ignoro e vado avanti....
                if (experiment == null) {
                    //console.warn("Esperimento non trovato per appuntamento:", appointments[i])
                    continue;
                }
                //console.log("DBAP: Appointment:", appointments[i]);
                for (let p = 0; p < experiment["labs_id"].length; p++) {
                    const lab_id = experiment["labs_id"][p];

                    // lab id di uno specifico esperimento 
                    sessionLabs[lab_id] = labs[lab_id];

                    const labCoordinates = [labs[lab_id]["coordinates"][0],
                    labs[lab_id]["coordinates"][1]]
                    paths.push({ "positions": [schoolCoordinates, labCoordinates], experiment })
                    //console.log("GEO FOUND PATH labs_id:",experiment["labs_id"], paths.length);   

                }
            }
        }
        //console.log(`GEOPATH: Build path validi trovati: ${paths.length}`)
        setSessionSchoolMarkers(Object.values(sessionSchools));
        setSessionLabMarkers(sessionLabs);
        setAppointmentToLabPaths(paths)
    }

    useEffect(() => {
        if (props.onlyLabs!=true) dispatch(UsersActions.willGetRegisteredUsers());
        setTimeout(() => { setClusterDisabledAtZoom(1) }, 0)
    }, []);


    useEffect(() => {
        //console.log("GEO appointments:", appointments)
        if (appointments != null && registeredUsers) {
            console.log("OSMD: buildPaths")
            buildPaths();
        }

        /*
        else
        {
            setAppointmentToLabPaths([]);
        setSessionLabMarkers({ });
        setSessionSchoolMarkers([]);
        }
        */

    }, [appointments, registeredUsersDict]);


    useEffect(() => {
        const registeredSchools = {}
        if (registeredUsers != null) {
            console.log("OSMD: registeredUsers build schoolMarkers:", registeredUsers);
            const regUsersDict = {}
            for (let i = 0; i < registeredUsers.length; i++) {
                try {
                    const school = registeredUsers[i]["school"]
                    regUsersDict[registeredUsers[i]["id"]] = registeredUsers[i]

                    const codiceMec = getSchoolCode(school);
                    ////console.log("GEOPATH: SCHOOLS COD MEC:", codiceMec)
                    if (codiceMec == null || schools[codiceMec] == null) continue;

                    if (registeredSchools[codiceMec] == null)
                        registeredSchools[codiceMec] = { "school": schools[codiceMec], "count": 1 }
                    else {
                        registeredSchools[codiceMec]["count"] += 1
                    }
                    console.log(`OSMD: ${codiceMec} count: ${registeredSchools[codiceMec]["count"]}`);
                } catch (error) {
                    console.log("OSMD: eccezione in build schoolMarkers", error);
                    continue;
                }

            }
            // aggiungo anche le scuole che sono state usate
            setSchoolMarkers(Object.values(registeredSchools));
            console.log("OSMD: registeredUsers:", registeredUsers);
            console.log("OSMD: registeredSchools:", Object.values(registeredSchools));
            // aggiorno il dizionario degli utenti che mi serve per intercettare
            // la scuola di titolarità come default per le sessioni sincrone
            setRegisteredUsersDict(regUsersDict);
        }
        else setSchoolMarkers([])
    }, [registeredUsers]);
    //[40.11511479841725, 9.032469344181852] -> 8
    //[42.002202972720376,9.953613281250002] -> 4
    const renderBody = () => {
        return (
            appointmentToLabPaths.length > 0 || props.onlyLabs==true ?
                <MapContainer style={{ maxHeight: props.maxHeight || "100vh" }}
                    center={[42.002202972720376, 4.963713281650000]} zoom={3}

                    scrollWheelZoom={true}>

                    <TileLayer
                        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    />
                    <LayersControl key={clusterDisabledAtZoom} position="topright">
                        <LayersControl.BaseLayer checked={props.onlyLabs==true}

                            name={`${t("Laboratori")} (${labs && Object.entries(labs).length})`}>
                            <MarkerClusterGroup

                                chunkedLoading
                                disableClusteringAtZoom={clusterDisabledAtZoom}
                            >
                                {labs && Object.entries(labs).map((entry) => (
                                    (entry[1]["coordinates"] && 
                                        isValidLatLng(entry[1]["coordinates"][0], entry[1]["coordinates"][1], "labs") &&
                                        <Marker key={entry[0]} position={[entry[1]["coordinates"][0], entry[1]["coordinates"][1]]}>
                                            <Popup minWidth="400" maxHeight="auto">
                                                <LabContextPreview experiments={experiments} lab={entry[1]} />
                                            </Popup>
                                        </Marker>
                                    )
                                ))}
                            </MarkerClusterGroup>
                        </LayersControl.BaseLayer>
                        {props.onlyLabs != true &&
                            <LayersControl.BaseLayer name={`${t("Scuole")} (${schoolMarkers.length})`}>
                                <MarkerClusterGroup
                                    disableClusteringAtZoom={clusterDisabledAtZoom}
                                    chunkedLoading
                                >
                                    {schoolMarkers && schoolMarkers.map((school) => {
                                        return (
                                            isValidLatLng(school["school"]["latitudine"], school["school"]["longitudine"], "schools") &&
                                            <Marker key={school["school"]["codice_meccanografico"]}
                                                icon={schoolMarkerIcon}
                                                position={[school["school"]["latitudine"], school["school"]["longitudine"]]}>
                                                <Popup minWidth="400" maxHeight="auto">
                                                    <SchoolContextPreview schoolContext={school} />
                                                </Popup>
                                            </Marker>
                                        )
                                    })

                                    }
                                </MarkerClusterGroup>
                            </LayersControl.BaseLayer>

                        }

                        {props.onlyLabs != true &&
                            <LayersControl.BaseLayer checked name={`${t("Laboratori e Scuole")} (${schoolMarkers.length + Object.entries(labs).length})`}>
                                <MarkerClusterGroup
                                    disableClusteringAtZoom={clusterDisabledAtZoom}
                                    chunkedLoading
                                >
                                    {schoolMarkers && schoolMarkers.map((school) => {
                                        return (
                                            isValidLatLng(school["school"]["latitudine"], school["school"]["longitudine"]) && 
                                            <Marker key={school["school"]["codice_meccanografico"]}
                                                icon={schoolMarkerIcon}
                                                position={[school["school"]["latitudine"], school["school"]["longitudine"]]}>
                                                <Popup minWidth="400" maxHeight="auto">
                                                    <SchoolContextPreview schoolContext={school} />
                                                </Popup>
                                            </Marker>
                                        )
                                    })

                                    }

                                    {labs && Object.entries(labs).map((entry) => (
                                        (entry[1]["coordinates"] && isValidLatLng(entry[1]["coordinates"][0], entry[1]["coordinates"][1], "labs") &&
                                            <Marker key={entry[0]} position={[entry[1]["coordinates"][0], entry[1]["coordinates"][1]]}>
                                                <Popup minWidth="400" maxHeight="auto">
                                                    <LabContextPreview experiments={experiments} lab={entry[1]} />
                                                </Popup>
                                            </Marker>
                                        )
                                    ))}
                                </MarkerClusterGroup>
                            </LayersControl.BaseLayer>
                        }


                        {props.onlyLabs != true &&

                            <LayersControl.BaseLayer name={`${t("Sessioni sincrone")} (${appointmentToLabPaths?.length || 0})`}>


                                <LayerGroup>

                                    {
                                        appointmentToLabPaths && appointmentToLabPaths.map((appToLab, index) => {
                                            return (
                                                <Polyline key={`polyline_${index}`}
                                                    positions={appToLab["positions"]}
                                                    pathOptions={{ color: "red" }}
                                                >
                                                    <Popup key={`popup_${index}`} minWidth="400" maxHeight="auto">
                                                        <ExperimentContextPreview appointments={appointments} experiment={appToLab["experiment"]} />
                                                    </Popup>
                                                </Polyline>

                                            )
                                        })
                                    }

                                    {sessionLabMarkers && Object.entries(sessionLabMarkers).map((entry) => (
                                        (entry[1]["coordinates"]  && isValidLatLng(entry[1]["coordinates"][0], entry[1]["coordinates"][1], "sessions") &&
                                            <Marker icon={labMarkerIcon} key={entry[0]} position={[entry[1]["coordinates"][0], entry[1]["coordinates"][1]]}>
                                                <Popup minWidth="400" maxHeight="auto">
                                                    <LabContextPreview experiments={experiments} lab={entry[1]} />
                                                </Popup>
                                            </Marker>
                                        )
                                    ))}


                                    {sessionSchoolMarkers && sessionSchoolMarkers.map((school) => {
                                        return ( isValidLatLng(school["school"]["latitudine"], school["school"]["longitudine"], "sessionSchool") &&
                                            <Marker key={school["school"]["codice_meccanografico"]}
                                                icon={schoolMarkerIcon}
                                                position={[school["school"]["latitudine"], school["school"]["longitudine"]]}>
                                                <Popup minWidth="400" maxHeight="auto">
                                                    <SchoolContextPreview schoolContext={school} />
                                                </Popup>
                                            </Marker>
                                        )
                                    })

                                    }

                                </LayerGroup>

                            </LayersControl.BaseLayer>}
                    </LayersControl>
                    <ClusteringSelector checked={clusterDisabledAtZoom == 20} onClusterChange={(isClusteringEnabled) => {
                        console.log("ClusteringSelector:", isClusteringEnabled)
                        setClusterDisabledAtZoom(isClusteringEnabled ? 20 : 1)
                    }} />
                    {/* <LocationMarker /> */}
                </MapContainer> :
                <div style={{ display: "flex", justifyContent: "center" }}>
                    <Spinner />
                </div>
        )
    }

    return (
        props.hideContainer ? <> {renderBody()} </> :
            (
                <div style={{ marginRight: "10px", marginLeft: "10px" }}>
                    <Row className="m-0">
                        <Col xs="12">
                            <Card className="mb-4" style={{ padding: "10px", borderColor: "#007bff" }}>
                                <CardHeader id="mapHeader" style={{ backgroundColor: "#007bff", borderColor: "#007bff", paddingBottom: 0, color: 'white' }}>
                                    <CardTitle tag="h5">{`${t("La nostra rete")}`}
                                    </CardTitle>
                                </CardHeader>
                                <CardBody>
                                    {renderBody()}
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            )
    )

}