import React, { useEffect, useMemo, useRef, useState } from "react";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Label, FormGroup } from "reactstrap";
import { Card, CardBody } from "reactstrap";
import RialeAccordion from './RialeAccordion'
import { useTranslation } from 'react-i18next';
import ReactQuill, { Quill } from "react-quill-new";
import "quill/dist/quill.snow.css";
import "quill-table-ui/dist/index.css"; // Importa lo stile di quill-table-ui

import TableUI from "quill-table-ui"; // Importa il modulo delle tabelle
import ReactJson from 'react-json-view'

// Registra il modulo delle tabelle di quill-table-ui
Quill.register("modules/tableUI", TableUI);

// ✅ Registra il modulo delle tabelle
//Quill.register("modules/better-table", QuillBetterTable);


const modules = {
  toolbar: [
    [{ header: [1, 2, false] }],
    ["bold", "italic", "underline"],
    [{ list: "ordered" }, { list: "bullet" }],
    ["link", "image"],
    ["table"], // Pulsante per creare la tabella
  ],
  tableUI: {
    insertTable: true, // Abilita il pulsante per inserire tabelle
  },
  "better-table": {
    operationMenu: {
      items: {
        insertColumnRight: true,
        insertColumnLeft: true,
        insertRowUp: true,
        insertRowDown: true,
        deleteColumn: true,
        deleteRow: true,
      },
    },
  },
  tableUI: {
    insertTable: true, // Abilita il pulsante per inserire tabelle
    insertRowAbove: true, // Aggiungi l'opzione per inserire una riga sopra
    insertRowBelow: true, // Aggiungi l'opzione per inserire una riga sotto
    insertColumnLeft: true, // Aggiungi l'opzione per inserire una colonna a sinistra
    insertColumnRight: true, // Aggiungi l'opzione per inserire una colonna a destra
    deleteRow: true, // Aggiungi l'opzione per rimuovere una riga
    deleteColumn: true, // Aggiungi l'opzione per rimuovere una colonna
  }
};

export const ExperimentEditor = ({ experiment }) => {
  const { t, i18n } = useTranslation('frontend', { useSuspense: false });
  const quillRef = useRef(null);
  const [previewOpen, setPreviewOpen] = useState(false);


  const getNewEmptyExperiment = () => {
    const id = crypto.randomUUID()
    return {
      "id": id,
      "languages": [
        "IT"
      ],
      "labs_id": [
      ],
      "live": true,
      "disabled" : false,
      "qrcode_questions_url": "",
      "questions_spreadsheet_url": "",
      "organization": "",
      "titolo": "",
      "laboratorio": "",
      "descrizione": "",
      "tags": [
      ],
      "gradi": [
      ],
      "fasi": [
      ],
      "durata": "60",
      "ricercatori": [
      ],
      "opportunita_culturale": "",
      "frequenza_mensile": "",
      "periodo_erogazione": ["", ""],
      "timeline_pubbliche": [{ "id": "", "descrizione": t("Prossima pubblicazione") }
      ],
      "topic": [
      ]
    }
  }


  const [defaultExperiment, setDefaultExperiment] = useState(() => {
    // Aggiungi "languages" se non esiste nell'oggetto experiment
    if (!experiment) return getNewEmptyExperiment()
    else {
     
      const experimentClone = { ...getNewEmptyExperiment(), ...experiment }
      /*
      if (!experimentClone.languages) {
        experimentClone.languages = ["IT"];
      }

      if (!experimentClone.timeline_pubbliche) {
        experimentClone.timeline_pubbliche = [
          {
            id: "",
            descrizione: "Timeline master",
          },
        ];
      }
        */
      console.log("Creo un clone dell'esperimento:", experimentClone);
      return experimentClone;
    }


  });

  useEffect(()=>{
    console.log("default experiment:", defaultExperiment)
  }, [defaultExperiment])
  const [data, setData] = useState(defaultExperiment);

  const saveToLocalStorage = () => {
    localStorage.setItem("lastEditedExperiment" , JSON.stringify(data))
  }

  const loadFromLocalStorage = () => {

    const exp  = localStorage.getItem("lastEditedExperiment")
    if (exp!=null)
    {
      const loadedExperiment = JSON.parse(exp)
      if (loadedExperiment) {
        setDefaultExperiment(loadedExperiment);
        reset(loadedExperiment)
      }
    }
  }

  console.log("JS experiment:", defaultExperiment)
  const {
    control,
    handleSubmit,
    register,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: defaultExperiment,
  });

  const renderExperimentPreview = () => {

    return (<Modal isOpen={data!=null && previewOpen}>
      <ModalHeader style={{
        display: "flex",
        justifyContent: "center",
      }}><b>Esperimento:</b>{` ${data.id}`} <br></br>
      </ModalHeader>

      <ModalBody>
        <ReactJson style={{ padding: "10px" }} theme="monokai" displayDataTypes={false} collapsed={3}
          src={{[data.id] : data}} />
      </ModalBody>
      <ModalFooter style={{
        display: "flex",
        justifyContent: "right",
      }}>
        <Button color="primary" onClick={() => {
          setPreviewOpen(false);
        }}>Chiudi</Button>

      </ModalFooter>
    </Modal>)
  }


  // useFieldArray per gestire il campo "timeline_pubbliche"
  const { fields: timelineFields, append: appendTimeline, remove: removeTimeline } = useFieldArray({
    control,
    name: "timeline_pubbliche",
  });

  const { fields: labsIdFields, append: appendLabsId, remove: removeLabsId } = useFieldArray({
    control,
    name: "labs_id",
  });

  const { fields: ricercatoriFields, append: appendRicercatore, remove: removeRicercatore } = useFieldArray({
    control,
    name: "ricercatori",
  });

  const { fields: tagsFields, append: appendTag, remove: removeTag } = useFieldArray({
    control,
    name: "tags",
  });

  const { fields: gradiFields, append: appendGrado, remove: removeGrado } = useFieldArray({
    control,
    name: "gradi",
  });

  const { fields: fasiFields, append: appendFasi, remove: removeFasi } = useFieldArray({
    control,
    name: "fasi",
  });

  const { fields: languagesFields, append: appendLanguage, remove: removeLanguage } = useFieldArray({
    control,
    name: "languages",
  });

  useEffect(() => {
    console.log("NEW DATA:", data)
    if (document.hasFocus()) {
      navigator.clipboard.writeText(JSON.stringify({ [data.id]: data }))
        .then(() => console.log("Text copied successfully"))
        .catch((error) => console.error("Failed to copy text:", error));
    } else {
      console.warn("Cannot copy text because the document is not focused.");
    }
    reset(data);
  }, [data])

  useEffect(() => {
    if (experiment != null) {
      console.log("Experiment non nullo!!")
      setData(experiment);
      setDefaultExperiment({...getNewEmptyExperiment(),  ...experiment});
    }

  }, [i18n.language, experiment])

  const onSubmit = (formData) => {
    console.log("Updated Data:", formData);
    setData(formData);

  };

  return (
    <Card>
      <CardBody>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="mb-4">
            <label className="block font-medium">ID</label>
            <input {...register("id")} className="form-control" disabled />
          </div>

          <div className="mb-4">
            <label style={{ fontWeight: "bold" }} className="block font-medium">{t("Organization")}</label>
            <input {...register("organization")} className="form-control" />
          </div>

          <div className="mb-4">
            <label style={{ fontWeight: "bold" }} className="block font-medium">{t("titolo")}</label>
            <input {...register("titolo")} className="form-control" />
          </div>

          <div className="mb-4">
            <label style={{ fontWeight: "bold" }} className="block font-medium">{t("Laboratorio")}</label>
            <input {...register("laboratorio")} className="form-control" />
          </div>

          {/* Campo booleano live */}
          <div className="mb-4">
          
          </div>
          <div style={{display:"flex", justifyContent:"flex-start"}}>
          <FormGroup check>
              <Label check>
                <input
                  type="checkbox"
                  {...register("live")}
                />{" "}
                {t("Live")}
              </Label>
            </FormGroup>
            <FormGroup check>
              <Label check>
                <input
                  type="checkbox"
                  {...register("disabled")}
                />{" "}
                {t("Disabled")}
              </Label>
            </FormGroup>
          </div>
          {/* Campo booleano disabled */}
          <div className="mb-4">
           
          </div>




          <div className="mb-4">
            <label style={{ fontWeight: "bold" }} className="block font-medium">{t("descrizione")}</label>
            <Controller
              name="descrizione"
              control={control}
              render={({ field }) => (
                <ReactQuill  ref={quillRef} modules={modules}
                  theme="snow" {...field} className="rounded" />
              )}
            />
          </div>

          <div className="mb-4">
            <label style={{ fontWeight: "bold" }} className="block font-medium">{t("Opportunità culturale del Laboratorio")}</label>
            <Controller
              name="opportunita_culturale"
              control={control}
              render={({ field }) => (
                <ReactQuill {...field} className="rounded" />
              )}
            />
          </div>
          
          <div style={{display:"flex", justifyContent:"flex-start"}}>
          <div className="mb-4">
            <label style={{ fontWeight: "bold" }} className="block font-medium">{`${t("Durata")} `}</label>
            <input {...register("durata")} className="form-control" />
          </div>

          <div className="mb-4">
            <label style={{ fontWeight: "bold" }} className="block font-medium">{t("Frequenza")}</label>
            <input {...register("frequenza_mensile")} className="form-control" />
          </div>
          </div>
         

         

          {/* Campo qrcode_questions_url */}
      
          <div className="mb-4">
            <label style={{ fontWeight: "bold" }} className="block font-medium">{t("Qr Code Question url")}</label>
            <input {...register("qrcode_questions_url")} className="form-control" />
          </div>

          {/* Campo questions_spreadsheet_url*/}
          <div className="mb-4">
            <label style={{ fontWeight: "bold" }} className="block font-medium">{t("Questions Spreadsheet Url")}</label>
            <input {...register("questions_spreadsheet_url")} className="form-control" />
          </div>

          <div style={{display:"flex", justifyContent:"flex-start"}}>
          <div className="mb-4">
            <label style={{ fontWeight: "bold" }} className="block font-medium">{`${t("Da")} `}</label>
            <input {...register(`periodo_erogazione.${0}`)} className="form-control" />
          </div>

          <div className="mb-4">
            <label style={{ fontWeight: "bold" }} className="block font-medium">{`${t("A")} `}</label>
            <input {...register(`periodo_erogazione.${1}`)} className="form-control" />
          </div>
          </div>
         

        

          


          {/* Labs ID */}
          <RialeAccordion title={`${t("Labs ID")} (${labsIdFields?.length || "0"})`}>
            <div className="mb-4">
              {labsIdFields.map((field, index) => (
                <div key={field.id} className="align-items-center mb-2">
                  <input
                    {...register(`labs_id.${index}`)}
                    className="form-control me-2"
                  />
                  <Button color="danger" onClick={() => removeLabsId(index)}>
                    Remove
                  </Button>
                </div>
              ))}
              <Button color="primary" onClick={() => appendLabsId("")}>Add Lab ID</Button>
            </div>
          </RialeAccordion>

          {/* Languages */}
          <RialeAccordion title={`${t("Languages")} (${languagesFields?.length || "0"})`}>
            <div className="mb-4">
              {languagesFields.map((field, index) => (
                <div key={field.id} className="align-items-center mb-2">
                  <input
                    {...register(`languages.${index}`)}
                    className="form-control me-2"
                  />
                  <Button color="danger" onClick={() => removeLanguage(index)}>
                    Remove
                  </Button>
                </div>
              ))}
              <Button color="primary" onClick={() => appendLanguage("")}>Add Language</Button>
            </div>
          </RialeAccordion>

          {/* Tags */}
          <RialeAccordion title={`${t("Parole chiave")} (${tagsFields?.length || "0"})`} >
            <div className="mb-4">
              {tagsFields.map((field, index) => (
                <div key={field.id} className="align-items-center mb-2">
                  <input
                    {...register(`tags.${index}`)}
                    className="form-control me-2"
                  />
                  <Button color="danger" onClick={() => removeTag(index)}>
                    Remove
                  </Button>
                </div>
              ))}
              <Button color="primary" onClick={() => appendTag("")}>Add Tag</Button>
            </div>
          </RialeAccordion>


          {/* Fasi */}
          <RialeAccordion title={`${t("Fasi dell'esperimento")} (${fasiFields?.length || "0"})`}>
            <div className="mb-4">
              {fasiFields.map((field, index) => (
                <div key={field.id} className="mb-4">
                  <Controller
                    name={`fasi.${index}`}
                    control={control}
                    render={({ field }) => (
                      <ReactQuill {...field} className="rounded" />
                    )}
                  />
                  <Button color="danger" className="mt-2" onClick={() => removeFasi(index)}>
                    Remove Fase
                  </Button>
                </div>
              ))}
              <Button color="primary" onClick={() => appendFasi("")}>Add Fase</Button>

            </div>

          </RialeAccordion>

          {/* Gradi */}
          <RialeAccordion title={`${t("Destinatari")} (gradi) (${gradiFields?.length || "0"})`}>
            <div className="mb-4">
              {gradiFields.map((field, index) => (
                <div key={field.id} className="mb-4">
                  <Controller
                    name={`gradi.${index}`}
                    control={control}
                    render={({ field }) => (
                      <ReactQuill {...field} className="rounded" />
                    )}
                  />
                  <Button color="danger" className="mt-2" onClick={() => removeGrado(index)}>
                    Remove Destinatario
                  </Button>
                </div>
              ))}
              <Button color="primary" onClick={() => appendGrado("")}>Add Destinatario</Button>
            </div>

          </RialeAccordion>

          {/* Ricercatori */}
          <RialeAccordion title={`${t("Ricercatori")} (${ricercatoriFields?.length || "0"})`} >
            <div className="mb-4">
              {ricercatoriFields.map((field, index) => (
                <div key={field.id} className="mb-4">
                  <Controller
                    name={`ricercatori.${index}`}
                    control={control}
                    render={({ field }) => (
                      <ReactQuill {...field} className="rounded" />
                    )}
                  />
                  <Button color="danger" className="mt-2" onClick={() => removeRicercatore(index)}>
                    Remove Ricercatore
                  </Button>
                </div>
              ))}
              <Button color="primary" onClick={() => appendRicercatore("")}>Add Ricercatore</Button>
            </div>
          </RialeAccordion>



          {/* Timeline Pubbliche */}
          <RialeAccordion title={`${t("Timeline Pubbliche")} (${timelineFields.filter((tl) => tl["id"]?.length > 0).length})`}>
            <div className="mb-4">
              {timelineFields.map((field, index) => (
                <div key={field.id} className="mb-4 border p-3 rounded">
                  <div className="mb-2">
                    <label style={{ fontWeight: "bold" }}>{t("ID")}</label>
                    <input
                      {...register(`timeline_pubbliche.${index}.id`)}
                      className="form-control"
                    />
                  </div>
                  <div className="mb-2">
                    <label style={{ fontWeight: "bold" }}>{t("Descrizione")}</label>
                    <input
                      {...register(`timeline_pubbliche.${index}.descrizione`)}
                      className="form-control"
                    />
                  </div>
                  <Button color="danger" onClick={() => removeTimeline(index)}>
                    Remove
                  </Button>
                </div>
              ))}
              <Button
                color="primary"
                onClick={() =>
                  appendTimeline({
                    id: "", // Genera un ID unico
                    descrizione: "Timeline master"
                  })
                }
              >
                Add Timeline
              </Button>
            </div>
          </RialeAccordion>



          {/* Save Button */}
          <div style={{marginTop: "10px", display: "flex", 
            justifyContent: "space-evenly" }}>
            <Button color="success" type="submit" className="w-100">
              Copia modifiche negli appunti
            </Button>
            <Button color="primary" 
            onClick={(ev)=>{loadFromLocalStorage()}}  className="w-100">
              Load from Local Storage
            </Button>
            <Button color="primary" 
            onClick={(ev)=>{saveToLocalStorage()}}  className="w-100">
              Save to Local Storage
            </Button>
            <Button color="primary" onClick={(ev)=>{setPreviewOpen(!previewOpen)}}  className="w-100">
              Preview appunti copiati
            </Button>
            <Button disabled={data==null} color="warning" 
            onClick={(ev) => { reset(defaultExperiment) }} className="w-100">
              Annulla tutte le modifiche
            </Button>
          </div>

        </form>
      </CardBody>
      {renderExperimentPreview()}
    </Card>
  );
};


