import {ExperimentEditor} from "../components/CatalogEditor"
import { Header } from '../header'
import { Content } from '../components/Content';
import { useTranslation } from 'react-i18next';

export const ExperimentEditorPage = () => {
    const { t } = useTranslation('frontend', { useSuspense: false });

    return ( <>
        <Header className="mb-0 text-white" section={t("Catalogo - Aree tematiche, percorsi ed esperimenti")} showMenu={false} />
        <Content active="catalog" section="catalog" className="pt-3">
        <ExperimentEditor/>
        </Content>
      </>)
   
}