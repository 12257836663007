

import { Card, CardBody, CardHeader, CardTitle } from 'reactstrap';
import { MobileExperiment } from './MobileExperiment';
import ReactTooltip from "react-tooltip";
import { MobilePathExperiment } from './MobilePathExperiment';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from "react";
import { TOPIC_BUTTON_BG_COLOR, TOPIC_BUTTON_COLOR } from './Constants';
import { SearchBar } from '../SearchBar';
import { selectors as CatalogSelector } from '../../store/slices/catalog'
import { useSelector } from "react-redux";


// Le icone delle tematiche sono state scaricate dal sito seguente:
// https://icons8.com/icons


export const MobileTopic = (props) => {
  const { content } = props;
  
  const { t } = useTranslation('frontend', { useSuspense: false });
  const topicIcon = `/catalog/icons/${content?.icon || "laboratory.png"}`
  const filterText =  useSelector(CatalogSelector.getFilterText);

  useEffect(()=>{

    console.log("Filter Text in MobileTopic:", filterText);
  }, [filterText])
  const getTopicLength = () => {
    if (content?.experimenti == null) return 0;
    let res = 0;
    for (let i = 0; i < content.esperimenti.length; i++) {
      if (content.esperimenti[i]["type"] != "path") { res = res + 1 }
      else {
        res = res + content.esperimenti[i]["esperimenti"].length
      }
    }
    return res;
  }

  const amountOfExperiments = getTopicLength();

  const renderExperiments = (id) => {
    const experiments = content["esperimenti"];
    if (!experiments || experiments.length === 0) {
      return null; // Gestione caso array vuoto o non definito
    }
    //console.log("ID:", id);
    return content["esperimenti"].map((experiment, index) => {
      return (
        experiment["type"] == "path" ?
          <MobilePathExperiment topic={content} id={`PEID_${id}_${experiment.id}_${index}`}
            key={`PE_${id}_${experiment.id}_${index}`} content={experiment} /> :
          <MobileExperiment topic={content} key={`EXP_${id}_${experiment.id}_${index}`} content={experiment} />
      )

    })
  }

  if (content == null) return (<p>loading...</p>)
  else
    return (
      <div key={`CARD_ ${props.id}`} style={{
        display:"flex", flexDirection:"column" , justifyContent:"flex-start"
      }}>
        <SearchBar style={{marginBottom:"10px"}} mobileStyle content={filterText}/>
        <div data-tip={t("Area tematica")} data-for="tooltip_topic"

          style={{
            display: "flex", justifyContent: "space-between",
            backgroundColor: TOPIC_BUTTON_BG_COLOR,
            borderColor: "#007bff",
            paddingBottom: 0,
            color: TOPIC_BUTTON_COLOR
          }}>
          <div
            style={{ display: "flex", padding:"10px", alignItems: "center", flexDirection: "row" }}>
            <img src={topicIcon} style={{ marginRight: "8px", marginBottom: "8px" }}
              width="48" height="48"
              alt='not found' />
         
            <CardTitle tag="h5">{`${content.titolo}`}</CardTitle>

          </div>
        </div>
        <div style={{marginTop:"10px" , padding:"0px" , textAlign:"left"}} key={`CB_${props.id}`} >
          {renderExperiments(`${props.id}`)}
        </div>
        <ReactTooltip id="tooltip_topic" />
      </div>)
}