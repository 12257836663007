
import { selectors as CatalogSelectors } from '../../store/slices/catalog'
import { useEffect, useState } from 'react';
import { IconButton, Typography, Stack, Box } from '@mui/material';
import MobileContainer from './MobileContainer';
import { useDeviceType, useDeviceOrientation } from '../Timeline/Utils'
import { CATALOG_PAGE, TOPIC_BUTTON_BG_COLOR, TOPIC_BUTTON_COLOR } from './Constants';
import { push } from 'connected-react-router';
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from 'react-i18next';
import { SearchBar } from '../SearchBar';
import { useSearchParams } from '../Catalog';

export const TopicButton = ({ topic, onTopicSelected }) => {
    const deviceOrientation = useDeviceOrientation();
    

    return (
        <Box onClick={(ev) => { onTopicSelected(topic) }}
            sx={{
                cursor: 'pointer',
                display: 'flex',
                flexDirection: 'row',      // Disporre gli elementi orizzontalmente
                alignItems: 'center',      // Allineare gli elementi verticalmente al centro
                justifyContent: 'flex-start',
                backgroundColor: TOPIC_BUTTON_BG_COLOR, //, "#007bff"
                borderRadius: '15px',      // Bordo smussato per i pulsanti rettangolari
                padding: '10px 20px',      // Spaziatura interna
                width: `${deviceOrientation == 'landscape' ? 'auto' : '80vw'}`,             // Larghezza dinamica in base al contenuto
                maxWidth: '80vw',         // Larghezza massima
                textAlign: 'center',
                boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)', // Aggiungere un'ombra al pulsante
                margin: '10px',            // Margine tra pulsanti
            }}
        >
            {/* IconButton con immagine a sinistra */}
            <IconButton
                sx={{
                    backgroundColor: 'transparent',
                    borderRadius: '8px',
                    width: '40px',
                    height: '40px',
                    padding: 0,
                    marginRight: '10px',    // Spaziatura tra icona e testo
                }}
            >
                <img
                    src={`/catalog/icons/${topic.icon || "laboratory.png"}`}
                    alt="icon"
                    style={{
                        borderRadius: '8px', // Bordo leggermente smussato per l'immagine
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover',
                    }}
                />
            </IconButton>

            {/* Testo a destra dell'icona */}
            <Typography
                variant="body1"
                sx={{
                    color: TOPIC_BUTTON_COLOR,
                    fontSize: '16px', // Mantieni la dimensione del font fissa
                    overflow: 'hidden',
                    whiteSpace: 'nowrap', // Impedisce al testo di andare a capo
                    textOverflow: 'ellipsis', // Mostra "..." se il testo è troppo lungo
                }}
            >
                {topic.titolo}
            </Typography>
        </Box>
    );
};


const MobileCatalog = (props) => {
    const dispatch = useDispatch();
    const catalog = useSelector(CatalogSelectors.getFilteredCatalog(null));
    const { t } = useTranslation('frontend', { useSuspense: false });
    const searchParams  = useSearchParams()
    const filterText =  useSelector(CatalogSelectors.getFilterText);
    
    useEffect(()=>{

        console.log("MobileCatalog Filter Text:", filterText)
    }, [filterText])
    
    const renderCatalog = () => {
        return catalog.map((topic, index) => {
            if (topic.esperimenti?.length>0)
            return (
                <TopicButton key={`cibr_${index}`} topic={topic} onTopicSelected={(topic) => {
                    //setSelectedTopic(topic);

                    const url = `/topic/${topic.id}`
                    console.log("selezionato topic url:", url)
                    dispatch(push(url))
                    // dispatch(push(`/labs`))
                }} />
            )
            else return null;
        })
    }

    const renderRialeIntro = () => {
        return <div style={{ textAlign:"left"}}>
            <span style={{fontSize:"1.2em"}}>
            <b>RIALE-EU</b> (<b>R</b>emote <b>I</b>ntelligent <b>A</b>ccess to <b>L</b>ab <b>E</b>xperiment in <b>EU</b>rope) 
            {` ${t("riale_description")}`}
            </span>
            
        </div>
    }
    const renderCatalogMessage = () => {
        return (<div style={{textAlign:"center",fontWeight:"bold", marginTop:"10px", marginBottom:"5px"}}>
            {t("catalog_message")}
        </div>)
    }

    useEffect(() => {
        if (catalog != null) {
            renderCatalog();
        }

    }, [catalog])

    return (
        <MobileContainer>
            <div style={{
                display: "flex",
                flexWrap: "wrap",
                gap: "20px",
                justifyContent: "center",
                margin: "0px"
            }}> 
                   {renderRialeIntro()}
                <img
                    src="/catalog_banner.png"
                    style={{width:"100%", height:"auto", margin:"0px 0px 0px 0px"}}
                    alt="center"

                />
                {renderCatalogMessage()}
                <SearchBar mobileStyle content={filterText || searchParams.get("search")}/>
                {renderCatalog()}
            </div>
        </MobileContainer>
    )



}


export default MobileCatalog;