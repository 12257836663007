import { useParams } from 'react-router-dom'
import { MobileTopic } from '../components/mobile/MobileTopic';
import { selectors as CatalogSelector } from '../store/slices/catalog'
import { useSelector, useDispatch } from "react-redux";
import { useEffect, useState } from 'react';
import MobileContainer from '../components/mobile/MobileContainer';

export const MobileTopicPage = (props) => {
    const { id } = useParams();
    const myTopic = useSelector(CatalogSelector.getTopic(id));
    const [filteredTopic, setFilteredTopic] = useState(myTopic);
    const filteredIds =  useSelector(CatalogSelector.getFilteredIds);
    const filterText =  useSelector(CatalogSelector.getFilterText);
    
    useEffect(()=>{
        console.log("MPT FilterText:", filterText)
    }, [filterText])

    useEffect(()=>{
        if (myTopic==null) return
        if (filteredIds==null)
            setFilteredTopic(myTopic)
        else
          {
            const fTopic = {...myTopic}
            
            fTopic.esperimenti = myTopic.esperimenti.filter((esperimento)=>{
                return filteredIds.includes(esperimento.id) || filteredIds.includes(parseInt(esperimento.id))
            })
            setFilteredTopic(fTopic)
            console.log("myTopic, fTopic, filteredIds:::", myTopic.esperimenti, fTopic.esperimenti, filteredIds)
          }
          
          
    }, [myTopic, filteredIds])
    

    return (
        <MobileContainer>
            <MobileTopic content={filteredTopic}/>
        </MobileContainer>
   
)
}